import React from 'react';
import { Livelinelogo } from '../../../assets/dark-theme';
import './splashScreen.scss';
import Spinner from '../spin/SpinnerComponent';

const SplashScreen = () => {
    return (
        <div className='splashWrapper'>
            <img className='fadesplash' src={Livelinelogo} alt='Live line' />
            <Spinner spinning={true} />
        </div>
    );
};

export default SplashScreen;
