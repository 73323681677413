import Header from './Header';
import { Confirm } from '../../components/shared';
import { authActions } from '../../state/features/authReducer';
import { useDispatch } from 'react-redux';

const HeaderContainer = ({ mobile }) => {
    const dispatch = useDispatch();

    const onLogoutClick = () => {
        Confirm({
            title: 'Logout Confirmation',
            icon: '',
            content: <p>Are you sure you want to Logout?</p>,
            onOk() {
                return dispatch(authActions.logout());
            },
            onCancel() {},
            okText: 'Yes',
            cancelText: 'No',
            className: 'isCancel',
        });
    };

    return <Header mobile={mobile} onLogoutClick={onLogoutClick} />;
};

export default HeaderContainer;
