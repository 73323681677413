// React Modules
import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import ErrorBoundary from "./ErrorBoundary";

// Libraries
import { useErrorBoundary } from "react-error-boundary";

const ErrorBoundaryContainer = () => {
  
  const { resetBoundary } = useErrorBoundary(); // react-error-boundary function to reset the error- (hasError: false)
  const navigate = useNavigate();

  // Set navigation to the Contact Us page (reset the error and navigate to the Dashboard)
  const contactUsHandler = () => {
    resetBoundary(); // This resets the error that was thrown; this is required before navigating away-
    navigate("/"); // Navigate to the Dashboard / Home page-
    window.open(
      "https://liveline.atlassian.net/servicedesk/customer/portals",
      "_blank"
    );
  };

  // Set navigation to the Dashboard/Home page
  const homeHandler = () => {
    resetBoundary(); // This resets the error that was thrown; this is required before navigating away-
    navigate("/"); // Navigate to the Dashboard / Home page-
  };

  return (
    <ErrorBoundary
      onContactUsClick={contactUsHandler}
      onHomeClick={homeHandler}
    />
  );
};

export default ErrorBoundaryContainer;
