import React from 'react';
import { Form } from 'antd';
import './form.scss';

const FormComponent = ({
    className = '',
    form = '',
    onFinish = () => {},
    onValuesChange = () => {},
    onFinishFailed = () => {},
    initialValues = {},
    validateTrigger = 'onSubmit',
    name = '',
    ...props
}) => {
    return (
        <Form
            className={className}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            form={form}
            onFinishFailed={onFinishFailed}
            initialValues={initialValues}
            validateTrigger={validateTrigger}
            name={name}
        >
            {props?.children}
        </Form>
    );
};

export default FormComponent;

const UseForm = () => {
    return Form.useForm();
};

export { UseForm };
