import React, { lazy } from 'react';

const Cells = lazy(() => import('./cells'));
const Products = lazy(() => import('./products'));
const Facilities = lazy(() => import('./facilities'));
const Equipment = lazy(() => import('./equipment'));

const AdminRoute = () => {
    return [
        {
            key: 'cells',
            path: '/general_admin/cells',
            component: <Cells />,
        },
        {
            key: 'products',
            path: '/general_admin/products',
            component: <Products />,
        },
        {
            key: 'facilities',
            path: '/general_admin/facilities',
            component: <Facilities />,
        },
        {
            key: 'equipment',
            path: '/general_admin/equipment',
            component: <Equipment />,
        },
    ];
};

export default AdminRoute;
