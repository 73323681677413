import React from 'react';
import { Tooltip } from 'antd';
import './toolTip.scss';

const TooltipComponent = ({ title = '', color = '', placement = 'right', arrowPointAtCenter = true, ...props }) => {
    return (
        <Tooltip title={title} color={color} placement={placement} arrow={arrowPointAtCenter}>
            {props.children}
        </Tooltip>
    );
};

export default TooltipComponent;
