import React from 'react';
import { Row } from 'antd';

const RowComponent = ({ className = '', style = {}, gutter = [24, 24], ...props }) => {
    return (
        <Row gutter={gutter} className={className} style={style}>
            {props.children}
        </Row>
    );
};

export default RowComponent;
