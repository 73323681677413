import React from 'react';
import { Dropdown } from 'antd';
import './dropdownMenu.scss';

const DropdownMenu = ({ trigger = '', placement = 'right', menu = {}, ...props }) => {
    return (
        <Dropdown arrow trigger={trigger} placement={placement} menu={menu}>
            {props.children}
        </Dropdown>
    );
};

export default DropdownMenu;
