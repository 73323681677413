import React from 'react';
import { notification } from 'antd';
import { CheckCircleFilled, ExclamationCircleFilled, CloseCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import './notification.scss';

export function SuccessNotification(title, message) {
    notification.open({
        message: title,
        description: message,
        className: 'NotificationLLPopover SuccessNotification',
        duration: 3,
        icon: <CheckCircleFilled style={{ color: '#12ad37' }} />,
    });
}

export function FailedNotification(title, message) {
    notification.open({
        message: title,
        description: message,
        duration: 3,
        className: 'NotificationLLPopover FailedNotification',
        icon: <CloseCircleFilled style={{ color: '#ea3939' }} />,
    });
}

export function WarningNotification(title, message) {
    notification.open({
        message: title,
        description: message,
        duration: 3,
        className: 'NotificationLLPopover WarningNotification',
        icon: <ExclamationCircleFilled style={{ color: '#a58806' }} />,
    });
}

export function InformationNotification(title, message) {
    notification.open({
        message: title,
        description: message,
        duration: 3,
        className: 'NotificationLLPopover InformationNotification',
        icon: <InfoCircleFilled style={{ color: '#006b8c' }} />,
    });
}