import configurationGreyIcon from "./icons/configuration-grey.svg";
import EditYellowIcon from "./icons/edit-yellow.png";
import moduleAlarmIcon from "./icons/module-alarm.svg";
import moduleBasicPlatformIcon from "./icons/module-basic-platform.svg";
import moduleControlsIcon from "./icons/module-controls.svg";
import moduleReportingIcon from "./icons/module-reporting.svg";
import productSuiteIcon from "./icons/product-suite-20px.png";
import searchIcon from "./icons/search.png";
import userYellowIcon from "./icons/user-yellow.png";
import LivelineWhitelogo from "./icons/liveline-white-logo.png";
import customersYellowLogo from "./icons/customers-yellow.png";
import configurationYellow from "./icons/configuration-yellow.png";
import facilitiesYellowIcon from "./icons/facilities-yellow.png";
import serversummaryYellow from "./icons/server-summary-yellow.png";
import logo from "./icons/logo.svg";
import customersGreyLogo from "./icons/customers-grey.svg";
import facilities from "./icons/facilities-1.svg";
import serversummaryGrey from "./icons/surver-summary-grey.svg";
import dashboard from "./icons/dashboard-1.svg";
import documentation from "./icons/documentation-1.svg";
import admin from "./icons/general-admin-1.svg";
import liveHistoricalData from "./icons/live-hystorical-data-1.svg";
import monitorLiveData from "./icons/monitor-live-data-old-1.svg";
import monitorRules from "./icons/monitor-rules-1.svg";
import monitorML from "./icons/monitor-machine-learning-1.svg";
import reporting from "./icons/reporting-1.svg";
import controls from "./icons/controls-admin-1.svg";
import MLAdmin from "./icons/ml-1.svg";
import predictiveAlarmsAdmin from "./icons/predictive-alarms-admin-1.svg";
import monitorStack from "./icons/monitor-stack-1.svg";
import cells from "./icons/cells-1.svg";
import equipment from "./icons/equipment-1.svg";
import products from "./icons/products-1.svg";
import graphsAndCharts from "./icons/graphs-charts-1.svg";
import impactKPI from "./icons/impact-(kpi)-1.svg";
import rulesAndConfiguration from "./icons/rules-configuration-1.svg";
import MLConfig from "./icons/ml-1.svg";
import DPK from "./icons/dpk-1.svg";
import LPP from "./icons/lpp-1.svg";
import LCPTrained from "./icons/lcp-1.svg";
import backTest from "./icons/back-test-1.svg";
import ghostScheduler from "./icons/ghost-scheduler-1.svg";
import lcpPerformance from "./icons/lcp-performance.svg";
import predictiveAlarmsMonitor from "./icons/predictive-alarm-monitor-1.svg";
import predictiveAlarmConfiguration from "./icons/predictive-alarm-configuration-1.svg";
import MenutoogleIcon from "./icons/menu-yellow.svg";
import help from "./icons/help.svg";
import info from "./icons/info.svg";
import ML_Control_1 from "./icons/ml-control-1.svg";
import Signal_Data_New_1 from "./icons/signal-data-new-1.svg";
import VML_1 from "./icons/vml-1.svg";
import Livelinelogo from "./icons/livelinelogo.png";

import report8827 from "./images/report-8827.png";
import report9092 from "./images/report-9092.png";
import reportRunHealth from "./images/report-runhealthsnapshot.png";

import predictiveAlarms from "./images/predictive-alarms.png";
import cpk_1 from "./images/demo-cpk-1.png";
import cpk_2 from "./images/demo-cpk-2.png";
import cpk_3 from "./images/demo-cpk-3.png";
import cpk_4 from "./images/demo-cpk-4.png";
import cpk_5 from "./images/demo-cpk-5.png";
import LiveLine_technologies_logo from "./icons/liveline-technologies-logo.png";
import jb_enterprises_1200px from "./icons/jb-enterprises-1200px.png";
import LivelineMobile from "./icons/liveline-fav.svg";
import cslogo2 from "./icons/cslogo2.png";
//import Liveline from './icons/liveline-white.svg';// Original Logo
import Liveline from "./icons/ll_icon_full_height_logo.svg"; // Updated Logo
import leftArrow from "./icons/leftarrow.svg";
import rightArrow from "./icons/right-arrow.svg";
import ProductSuiteImage from "./icons/product-suite.png";

import accordionUp from "./icons/up.svg";
import accordionDown from "./icons/down.svg";
import historicalRunsIcon from "./icons/historical-runs-02.svg";
import reportingModule from "./icons/reporting-module.png";
import imageSlashRegularModule from "./icons/image-slash-regular.svg";
import controlsModule from "./icons/controls-module.png";
import basicPlatformModule from "./icons/basic-platform.png";
import alarmModule from "./icons/alarm-module.png";
import noImage from "./icons/no-image.svg";
import contactUsIcon from "./icons/contact-us.svg";
import plug from "./icons/plug.svg";

const reportImages = {
  report8827,
  report9092,
  reportRunHealth,
  predictiveAlarms,
  cpk_1,
  cpk_2,
  cpk_3,
  cpk_4,
  cpk_5,
};

export {
  configurationGreyIcon,
  EditYellowIcon,
  moduleAlarmIcon,
  moduleBasicPlatformIcon,
  moduleControlsIcon,
  moduleReportingIcon,
  productSuiteIcon,
  searchIcon,
  userYellowIcon,
  LivelineWhitelogo,
  customersYellowLogo,
  configurationYellow,
  facilitiesYellowIcon,
  serversummaryYellow,
  logo,
  customersGreyLogo,
  facilities,
  serversummaryGrey,
  dashboard,
  documentation,
  admin,
  liveHistoricalData,
  monitorLiveData,
  monitorRules,
  monitorML,
  reporting,
  controls,
  MLAdmin,
  predictiveAlarmsAdmin,
  monitorStack,
  cells,
  equipment,
  products,
  graphsAndCharts,
  impactKPI,
  rulesAndConfiguration,
  MLConfig,
  DPK,
  LPP,
  LCPTrained,
  backTest,
  ghostScheduler,
  lcpPerformance,
  predictiveAlarmsMonitor,
  predictiveAlarmConfiguration,
  MenutoogleIcon,
  help,
  info,
  ML_Control_1,
  Signal_Data_New_1,
  VML_1,
  Livelinelogo,
  LiveLine_technologies_logo,
  jb_enterprises_1200px,
  reportImages,
  LivelineMobile,
  Liveline,
  leftArrow,
  rightArrow,
  ProductSuiteImage,
  accordionUp,
  accordionDown,
  historicalRunsIcon,
  reportingModule,
  imageSlashRegularModule,
  controlsModule,
  basicPlatformModule,
  alarmModule,
  noImage,
  contactUsIcon,
  plug,
  cslogo2,
};
