import React from 'react';

import { Input } from 'antd';
import './inputPassword.scss';

const InputPasswordComponent = ({
    value = '',
    className = '',
    onChange = () => { },
    placeholder = '',
    disabled = false,
    visibilityToggle = {}
}) => {
    return (
        <Input.Password
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            className={className}
            visibilityToggle={visibilityToggle}
        />
    );
};

export default InputPasswordComponent;