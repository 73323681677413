import React from 'react';
import './textEllipsis.scss';

const TextEllipsisComponent = ({ className = '', ellipsis = true, content, style = {} }) => {
    return (
        <div className={`ellipsis ${className}`} ellipsis={ellipsis.toString()} style={style} title={content}>
            {content}
        </div>
    );
};

export default TextEllipsisComponent;
