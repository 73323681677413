// React Modules
import React from "react";

// Components
import { Button } from "../../../components/shared";

// CSS files
import "./errorBoundary.scss";

// Liveline Logo
import logo from "../../../assets/dark-theme/icons/ll_icon_full_height_logo.svg";
 
const ErrorBoundary = ({
  onContactUsClick = () => {},
  onHomeClick = () => {},
}) => {
  return (
    
    <div className="errorBoundary_liveline_container">
      <div className="errorBoundary_livelineLogo">
        <img src={logo} alt="logo" className="errorBoundary_logoIMG" />
      </div>
      <div>
        <p className="errorBoundary_msg">Oops! Something went wrong!</p>
        <div className="errorBoundary_btnContainer">
          <Button
            className="errorBoundary_homeBtn"
            type="primary"
            onClickEvent={onHomeClick}
            disabled={false}
          >
            Home
          </Button>
          <Button
            className="errorBoundary_contactBtn"
            type="primary"
            onClickEvent={onContactUsClick}
            disabled={false}
          >
            Contact Us
          </Button>
        </div>
      </div>
    </div>
    
  );
};

export default ErrorBoundary;
