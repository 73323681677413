import React, { useState, useCallback, useEffect } from 'react';

import Login from './Login';
import { authActions } from '../../../state/features/authReducer';
import { useDispatch, useSelector } from 'react-redux';

const LoginContainer = () => {
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordErrMsg, setPasswordErrMsg] = useState('');
    const [formMode, setFormMode] = useState('LOGIN'); // LOGIN | FORGOT_PASSWORD | CREATE_NEW_PASSWORD

    const passwordMismatchErrMsg = 'Passwords must match.';

    const authChallenge = useSelector((x) => x.auth?.authChallengeData);
    const errorMessage = useSelector((x) => x.auth.error);
    const authLoading = useSelector((x) => x?.auth?.loading);
    const dispatch = useDispatch();

    const onSubmit = useCallback(async () => {
        if (username.length > 1 && password.length > 1) {
            if (Boolean(authChallenge)) {
                if (password === confirmPassword) {
                    onRespondToNewPassword();
                } else {
                    setPasswordErrMsg(passwordMismatchErrMsg);
                }
            } else {
                dispatch(authActions.login({ username, password }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username, password, confirmPassword]);

    useEffect(() => {
        if (authChallenge) {
            setPassword('');
            setConfirmPassword('');
            setFormMode('CREATE_NEW_PASSWORD');
        }
    }, [authChallenge]);

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === 'Enter') {
                onSubmit();
            }
        },
        [onSubmit]
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const clickGate = Boolean(authLoading) && !authChallenge;

    function onForgotPassword() {
        setFormMode('FORGOT_PASSWORD');
    }

    async function onRespondToNewPassword() {
        dispatch(authActions.respondToChallenge({ username, password, sessionToken: authChallenge?.Session }));
    }

    const toDisplayString = () => {
        if (formMode === 'LOGIN') return 'Login';
        if (formMode === 'CREATE_NEW_PASSWORD') return 'Create New Password';
        return 'Liveline';
    };

    const onChangePassword = (value) => {
        setPassword(value);
        setPasswordErrMsg('');
    };

    const onChangeConfirmPassword = (value) => {
        setConfirmPassword(value);
        setPasswordErrMsg('');
    };

    const title = toDisplayString(formMode);

    const onUserNameChange = (value) => {
        setUserName(value);
    }

    const onFormModeChange = (formMode) => {
        setFormMode(formMode)
    }

    return (
        <Login
            formMode={formMode}
            title={title}
            errorMessage={errorMessage}
            passwordErrMsg={passwordErrMsg}
            username={username}
            clickGate={clickGate}
            onUserNameChange={onUserNameChange}
            password={password}
            onChangePassword={onChangePassword}
            confirmPassword={confirmPassword}
            onChangeConfirmPassword={onChangeConfirmPassword}
            onForgotPassword={onForgotPassword}
            onSubmit={onSubmit}
            handleKeyDown={handleKeyDown}
            onFormModeChange={onFormModeChange}
        />
    );
};

export default LoginContainer;