import React from 'react';
import { Form } from 'antd';
import './formItem.scss';
const FormItem = Form.Item;

const FormItemComponent = ({
    className = '',
    rules = [],
    name = '',
    onClickEvent = () => {},
    ...props
}) => {
    return props?.valuePropName ? (
        <Form.Item
            className={className}
            name={name}
            rules={rules}
            onClick={onClickEvent}
            valuePropName={props?.valuePropName}
        >
            {props?.children}
        </Form.Item>
    ) : (
        <Form.Item
            className={className}
            name={name}
            rules={rules}
            onClick={onClickEvent}
        >
            {props?.children}
        </Form.Item>
    );
};

export default FormItemComponent;
