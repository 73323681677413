import React, { lazy } from 'react';

const Monitor = lazy(() => import('./monitor/index'));

const PredictiveAlarmsRoute = () => {
    return [
        {
            key: 'monitor',
            path: '/predictive_alarms_admin/monitor',
            component: <Monitor />,
        },
    ];
};

export default PredictiveAlarmsRoute;
