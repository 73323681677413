import React from 'react';
import { DatePicker } from 'antd';
import './datePicker.scss';

const DatePickerComponent = ({
    value = '',
    className = '',
    onChangeEvent = () => {},
    style = {},
    format = 'DD-MMM-YYYY',
    ...otherProps
}) => {
    return (
        <>
            <DatePicker
                className={className}
                value={value}
                onChange={onChangeEvent}
                style={style}
                format={format}
                { ...otherProps }
            />
        </>
    );
};

export default DatePickerComponent;
