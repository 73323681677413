// Imported Components from Ant Design
import { theme } from 'antd';
// Set the Ant Design theme to darkAlgorithm (Default is light)
const { darkAlgorithm } = theme;

export const antdTheme = {
    algorithm: darkAlgorithm,

    components: {
        Table: {
            borderRadius: '0px',
        },
        Pagination: {
            borderRadius: '4px',
            colorPrimary: '#555859',
            colorPrimaryHover: '#006b8c',   
        },
        Input: {
            borderRadius: '4px',
            colorPrimary: '#006b8c',
            colorPrimaryHover: '#006b8c',
            colorTextPlaceholder: '#555859'
        },
        Button: {
            borderRadius: '4px',
            colorPrimary: '#006b8c',
            colorPrimaryHover: '#006b8c',
        },
        Select: {
            borderRadius: '4px',
            colorPrimaryHover: '#006b8c',
            colorTextPlaceholder: '#555859'
        },
        Switch: {
            borderRadius: '4px',
            colorPrimary: '#006b8c',
            colorPrimaryHover: '#006b8c',
        },
        InputNumber: {
            borderRadius: '4px',
            colorPrimaryHover: '#006b8c',
            colorTextPlaceholder: '#555859'
        },
        DatePicker: {
            borderRadius: '4px',
            colorPrimary: '#006b8c',
            colorPrimaryHover: '#006b8c',
        },
        Tour: {
            colorPrimary: '#006b8c',
        },
        Steps: {
            colorPrimary: '#006b8c',
        },
    },
};
