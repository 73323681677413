import Footer from './Footer';

const FooterContainer = ({ onToogle, mobileCollapse = () => {} }) => {
    return (
        <div>
            <Footer onToogle={onToogle} mobileCollapse={mobileCollapse} />
        </div>
    );
};

export default FooterContainer;
