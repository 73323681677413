import React, { lazy } from 'react';

const Configuration = lazy(() => import('./index'));

const ConfigurationRoute = () => {
    return [
        {
            key: 'configuration',
            path: '/configuration',
            component: <Configuration />,
        },
    ];
};

export default ConfigurationRoute;
