import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import { reducers } from './features';

export default function configureStore(initialState) {
    const sagaMiddleware = createSagaMiddleware();

    const middlewares = [sagaMiddleware, thunk];

    const composeEnhancers = composeWithDevTools({});
    const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)));

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./features', () => {
            /* eslint-disable-next-line */
            const { reducers } = require('./features');
            store.replaceReducer(reducers);
        });
    }
    return store;
}
