import React, { lazy } from 'react';

const Dashboard = lazy(() => import('./index'));

const DashboardRoute = () => {
    return [
        {
            key: 'dashboard',
            path: '/',
            component: <Dashboard />,
        },
        {
            key: 'dashboard',
            path: '/dashboard',
            component: <Dashboard />,
        },
    ];
};

export default DashboardRoute;
