import { Navigate, Route, Routes } from 'react-router-dom';
import React, { Suspense } from 'react';
import SplashScreen from '../components/shared/splash-screen/SplashScreenComponent';
import PrivateRoutes from './private-routes';
import DashboardRoute from './content/dashboard/routes';
import AdminRoute from './content/admin/routes';
import MonitorRoute from './content/monitor/routes';
import CustomerRoute from './content/customers/routes';
import ConfigurationRoute from './content/configurations/routes';
import HistoricalRoute from './content/historical/routes';
import ServerSummaryRoute from './content/server-summary/routes';
import ControlsRoute from './content/controls/routes';
import MonitorStackRoute from './content/monitor-stack/routes';
import ReportingRoute from './content/reporting/routes';
import PredictiveAlarmsRoute from './content/predictive-alarms/routes';
import MachineLearningRoute from './content/machine-learning/routes';

const ContentRoutes = () => {
    const dashboardRoute = DashboardRoute();
    const adminRoute = AdminRoute();
    const monitorRoute = MonitorRoute();
    const customerRoute = CustomerRoute();
    const configurationRoute = ConfigurationRoute();
    const historicalRoute = HistoricalRoute();
    const serverSummaryRoute = ServerSummaryRoute();
    const controlsRoute = ControlsRoute();
    const monitorStackRoute = MonitorStackRoute();
    const reportingRoute = ReportingRoute();
    const predictiveAlarmsRoute = PredictiveAlarmsRoute();
    const machineLearningRoute = MachineLearningRoute();

    const routeObj = [
        ...dashboardRoute,
        ...adminRoute,
        ...monitorRoute,
        ...customerRoute,
        ...configurationRoute,
        ...historicalRoute,
        ...serverSummaryRoute,
        ...controlsRoute,
        ...monitorStackRoute,
        ...reportingRoute,
        ...predictiveAlarmsRoute,
        ...machineLearningRoute,
    ];

    return (
        <Routes>
            <Route path='/' element={<PrivateRoutes />}>
                {routeObj &&
                    routeObj.map((route) => {
                        return (
                            <Route
                                key={route?.key}
                                path={route?.path}
                                element={<Suspense fallback={<SplashScreen />}>{route?.component}</Suspense>}
                            />
                        );
                    })}
            </Route>
            <Route key={'any'} path='*' element={<Navigate to={'/'} />} />
        </Routes>
    );
};

export default ContentRoutes;
