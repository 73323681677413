import React from 'react';
import { Menu } from 'antd';
import '../tabs/tabs.scss';
import './menuTabs.scss'

const MenuTabComponent = ({ items = [], current, onClick = () => {} }) => {
    return (
        <div className='tabMenuList'>
            <Menu onClick={onClick} selectedKeys={[current]} mode='horizontal' items={items}  />
        </div>
    );
};

export default MenuTabComponent;
