import React from 'react';

import { SelectComponent, Button, InformationPopUp } from '../../../components/shared';
import './facilityCellPartDropdowns.scss';

const FacilityCellPartDropdowns = ({
    titleMessage = '',
    bodyMessage = '',
    facilityHandler = () => {},
    dropdownValues = {},
    facilityList = {},
    cellHandler = () => {},
    cellList = {},
    profileHandler = () => {},
    partNumberList = {},
    clearDropdownHandler = () => {},
}) => {
    return (
        <>
            <div className='fcpDropdownContainer'>
                <div className='item'>
                    <div className='fcpDropdownFacilityLabel'>Facility</div>
                    <SelectComponent
                        placeholder='Select a Facility'
                        className='fcpDropdownFacilitySelect'
                        onChangeEvent={facilityHandler}
                        value={dropdownValues?.facility?.text}
                        loading={facilityList?.loading ?? false}
                        allowClear
                        options={facilityList?.data ?? []}
                        disabledOptions={['Loading']}
                    />
                </div>
                <div className='item'>
                    <div className='fcpDropdownCellLabel'>Cell</div>
                    <SelectComponent
                        placeholder='Select a Cell'
                        className='fcpDropdownCellSelect'
                        onChangeEvent={cellHandler}
                        value={dropdownValues?.cell?.text}
                        loading={cellList?.loading ?? false}
                        allowClear
                        options={cellList?.data ?? []}
                        disabledOptions={['Loading', 'Select a Facility']}
                    />
                </div>
                <div className='item'>
                    <div className='fcpDropdownPartNumberLabel'>Product</div>
                    <SelectComponent
                        placeholder='Select a Product'
                        className='fcpDropdownPartNumberSelect'
                        onChangeEvent={profileHandler}
                        value={dropdownValues?.profile?.text}
                        loading={partNumberList?.loading ?? false}
                        allowClear
                        options={partNumberList?.data ?? []}
                        disabledOptions={['Loading', 'Select a Cell']}
                        showSearch={true} // Search is for pre-loaded data only-
                    />
                </div>
                <div className='clearBtn'>
                    <Button
                        className='fcpDropdownClearBtn fcpDropdownPartNumberSelect'
                        onClickEvent={clearDropdownHandler}
                        type={''}
                    >
                        Clear
                    </Button>
                </div>
                <div className='fcpDropdownInfoIcon'>
                    <InformationPopUp title={titleMessage} content={bodyMessage} />
                </div>
            </div>
        </>
    );
};

export default FacilityCellPartDropdowns;
