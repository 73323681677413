import React from 'react';
import { Input } from 'antd';
import './searchInput.scss';

const { Search } = Input;
const SearchInputComponent = ({
    placeholder = 'Search',
    className = 'searchInputField',
    onSearch = () => {},
    onChangeEvent = () => {},
    style = {},
    disabled = false,
    allowClear = true,
    size = 'large',
    value = '',
}) => {
    return (
        <Search
            placeholder={placeholder}
            className={className}
            onChange={onChangeEvent}
            allowClear={allowClear}
            size={size}
            onSearch={onSearch}
            style={style}
            disabled={disabled}
            value={value}
        />
    );
};

export default SearchInputComponent;
