import React, { lazy } from 'react';

const MonitorStack = lazy(() => import('./index'));

const MonitorStackRoute = () => {
    return [
        {
            key: 'monitor_stack',
            path: '/monitor_stack',
            component: <MonitorStack />,
        },
    ];
};

export default MonitorStackRoute;
