import React, { lazy } from 'react';

const Historical = lazy(() => import('./index'));

const HistoricalRoute = () => {
    return [
        {
            key: 'reportshistorical',
            path: '/reportshistorical',
            component: <Historical />,
        },
    ];
};

export default HistoricalRoute;
