import React, { lazy } from 'react';

const BackTest = lazy(() => import('./back-test/index'));
const Dpk = lazy(() => import('./dpk/index'));
const Lpp = lazy(() => import('./lpp/index'));
const Lcp = lazy(() => import('./lcp/index'));
const LcpPerformance = lazy(() => import('./lcp-performance/index'));
const GhostScheduler = lazy(() => import('./ghost-scheduler/index'));
const LcpPerformanceDetails = lazy(() => import('./lcp-performance/lcp-performance-details/index'));

const MachineLearningRoute = () => {

    return [
        {
            key: 'dpk',
            path: '/ml_admin/dpk',
            component: <Dpk />,
        },
        {
            key: 'lpp',
            path: '/ml_admin/lpp',
            component: <Lpp />,
        },
        {
            key: 'lcp_trained',
            path: '/ml_admin/lcp_trained',
            component: <Lcp />,
        },
        {
            key: 'backtest',
            path: '/ml_admin/backtest',
            component: <BackTest />,
        },
        {
            key: 'ghost_scheduler',
            path: '/ml_admin/ghost_scheduler',
            component: <GhostScheduler />,
        },
        {
            key: 'lcp_performance',
            path: '/ml_admin/lcp_performance',
            component: <LcpPerformance />,
        },
        {
            key: 'lcp_performance_details',
            path: '/ml_admin/lcp_performance_details',
            component: <LcpPerformanceDetails />,
        },
    ];
};

export default MachineLearningRoute;
