import React, { lazy } from 'react';

const ImpactKpi = lazy(() => import('./impact-kpi'));
const RunReports = lazy(() => import('./run-reports'));

const ReportingRoute = () => {
    return [
        {
            key: 'graphs_charts',
            path: '/reporting/graphs_charts',
            component: <RunReports />,
        },
        {
            key: 'impact_kpi',
            path: '/reporting/impact_kpi',
            component: <ImpactKpi />,
        },
    ];
};

export default ReportingRoute;
