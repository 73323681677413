import React from 'react';

import { Button } from 'antd';
import './button.scss';

const ButtonComponent = ({
    type = '',
    className = '',
    onClickEvent = () => {},
    style = {},
    disabled = false,
    ...props
}) => {
    return (
        <Button type={type} className={className} onClick={onClickEvent} style={style} disabled={disabled} {...props}>
            {props.children}
        </Button>
    );
};

export default ButtonComponent;
