import React from 'react';
import { Input } from 'antd';
const { TextArea } = Input;

const TextAreaComponent = ({
    value = '',
    className = '',
    onClickEvent = () => {},
    onChangeEvent = () => {},
    style = {},
    disabled = false,
    rows = '4',
}) => {
    return (
        <TextArea
            rows={rows}
            value={value}
            className={className}
            onClick={onClickEvent}
            onChange={onChangeEvent}
            style={style}
            disabled={disabled}
        />
    );
};

export default TextAreaComponent;
