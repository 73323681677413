import "./app.scss";
import ContentRoutes from "./layout/routes";
import { history } from "./utils/history";
import { useNavigate, useLocation } from "react-router-dom";

import { ErrorBoundary } from "react-error-boundary"; // Error catch library-
import ErrorBoundaryFallback from "../src/layout/private-routes/error-boundary/index"; //This is the fallback component-


function App() {
  // This needs to be updated to write the error to the backend-
  const errorHandler = (error, errorInfo) => {
    console.log("Logging: ", error, errorInfo);
  };

  history.location = useLocation();
  history.navigate = useNavigate();

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={errorHandler}>
      <ContentRoutes />
    </ErrorBoundary>
  );
}

export default App;
