import { createContext, useContext, useState } from 'react';

const SharedContext = createContext();

export const SharedObjectProvider = ({ children }) => {
    const [sharedContext, setSharedContext] = useState(null);

    const handleContextChange = (newContext) => {
        setSharedContext(newContext);
    };

    return <SharedContext.Provider value={{ sharedContext, handleContextChange }}>{children}</SharedContext.Provider>;
};

export const useSharedContext = () => {
    return useContext(SharedContext);
};
