import React from 'react';

/**
 * Embeds a PDF (report) into a page.
 * `src` is a required `prop`
 */
class Iframe extends React.Component {
    render() {
        const { src, altText = 'PDF Report', width = '100%', height = '100%' } = this.props;
        return (
            <>
                <iframe title={altText} src={src} width={width} height={height} style={{ border: 'none' }} />
            </>
        );
    }
}

Iframe.defaultProps = {
    width: '100%',
    height: '100%',
};
export default Iframe;
