import React from 'react';

import { ConfigProvider } from 'antd';

const ConfigProviderComponent = ({ className = '', theme = '', ...props }) => {
    return (
        <ConfigProvider className={className} theme={theme}>
            {props.children}
        </ConfigProvider>
    );
};

export default ConfigProviderComponent;
