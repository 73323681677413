import React, { lazy } from 'react';

const Customer = lazy(() => import('./index'));

const CustomerRoute = () => {
    return [
        {
            key: 'customers',
            path: '/customers',
            component: <Customer />,
        },
    ];
};

export default CustomerRoute;
