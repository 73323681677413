import React from 'react';
import { Input } from 'antd';
import './input.scss';

const InputComponent = ({
    value = '',
    className = '',
    onClickEvent = () => {},
    onChangeEvent = () => {},
    style = {},
    status = '',
    statusMsg = '',
    disabled = false,
    suffix = '',
    allowClear,
    placeholder = '',
    maxLength
}) => {
    return (
        <>
            <Input
                className={className}
                value={value}
                onClick={onClickEvent}
                onChange={onChangeEvent}
                style={style}
                status={status}
                disabled={disabled}
                suffix={suffix}
                allowClear={allowClear}
                placeholder={placeholder}
                maxLength={maxLength}
            />
            {statusMsg && <p className='mandatoryMsg'>{statusMsg}</p>}
        </>
    );
};

export default InputComponent;
