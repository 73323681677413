import React from 'react';
import { InputNumber } from 'antd';
import './inputNumber.scss';

const InputNumberComponent = ({
    value = '',
    className = '',
    onChangeEvent = () => {},
    style = {},
    status = '',
    statusMsg = '',
    addonAfter = '',
    type = 'number',
    min = 0,
    max,
    step = 1,
    placeholder = '',
    precision,
    formatter,
    parser,
    controls,
    disabled,
    onBlurEvent = () => {},
}) => {
    return (
        <>
            <InputNumber
                className={className}
                value={value}
                onChange={onChangeEvent}
                style={style}
                status={status}
                addonAfter={addonAfter}
                type={type}
                min={min}
                step={step}
                max={max}
                precision={precision}
                placeholder={placeholder}
                formatter={formatter}
                parser={parser}
                controls={controls}
                disabled={disabled}
                onBlur={onBlurEvent}
            />
            {statusMsg && <p className='mandatoryMsg'>{statusMsg}</p>}
        </>
    );
};

export default InputNumberComponent;
