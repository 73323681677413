import React, { useMemo } from 'react';
import { antdTheme, ConfigProviderComponent, DatePickerComponent, DurationDropdown } from '../index';
import './startDateDurationPicker.scss';
import RefreshButton from '../refresh-button/RefreshButton';
import { getIndexByKeyValue } from '../../../utils/commonFunctions';
import { durationOptions } from '../duration-dropdown/DurationDropdown';


const StartDateDurationPicker = ({
    selectedTimeRange,
    selectedTimeDuration,
    ...otherProps
}) => {
    const startPointValue = useMemo(() => {
        if (selectedTimeRange) {
            return selectedTimeRange?.[0];
        }
    }, [selectedTimeRange]);

    const selectedTimeDurationObj = useMemo(() => {
        const index = getIndexByKeyValue('value', selectedTimeDuration, durationOptions);

        return index !== -1 ? durationOptions[index] : {};
    })

    return (
        <>
            <ConfigProviderComponent theme={antdTheme}>
                <div className='startDateDurationRangeFilter'>

                    <div className='startDateDurationContainerBox'>
                        <div className='startDateDurationContainer'>
                            <div className='startDateDurationItem'>
                                <div className='durationLabel'>Duration</div>
                                <DurationDropdown
                                    onTimeDurationChange={otherProps.onDurationChangeEvent}
                                    selectedTimeDuration={selectedTimeDurationObj}
                                />
                            </div>
                            <div className='startDateDurationItem start'>
                                <div className='durationLabel'>Start Point</div>
                                <DatePickerComponent
                                    showTime={{
                                        format: 'HH:mm:ss',
                                    }}
                                    value={startPointValue}
                                    onChangeEvent={otherProps?.onStartChangeEvent}
                                    format='DD-MMM-YYYY HH:mm:ss'
                                    disabledDate={otherProps?.disabledDate}
                                    disabledTime={otherProps?.disabledTime}
                                    style={otherProps?.style}
                                    className={otherProps?.className}
                                />
                            </div>
                            <div className='startDateDurationItem begin'>
                                <div className='durationLabel' />
                                {otherProps.refresh && <RefreshButton className='refresh-button' refresh={otherProps.refresh} />}
                            </div>
                        </div>
                    </div>
                </div>
            </ConfigProviderComponent>
        </>
    );
};

export default StartDateDurationPicker;
