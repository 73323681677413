import React from 'react';
import { Spin } from 'antd';

const SpinComponent = ({ className = '', tip = '', spinning = false, ...props }) => {
    return (
        <Spin style={props?.style} tip={tip} spinning={spinning} className={className}>
            {props.children}
        </Spin>
    );
};

export default SpinComponent;
