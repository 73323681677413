import React from 'react';
import { DatePicker } from 'antd';
import './rangePicker.scss';
const { RangePicker } = DatePicker;

const RangePickerComponent = ({
    value = '',
    className = '',
    onChangeEvent = () => {},
    style = {},
    format = 'DD-MMM-YYYY',
    showTime,
    disabledDate,
    disabledTime,
    onCalendarChange,
    allowClear = false,
}) => {
    return (
        <>
            <RangePicker
                className={className}
                value={value}
                onChange={onChangeEvent}
                style={style}
                format={format}
                showTime={showTime}
                disabledDate={disabledDate}
                disabledTime={disabledTime}
                onCalendarChange={onCalendarChange}
                allowClear={allowClear}
            />
        </>
    );
};

export default RangePickerComponent;
