import React from 'react';

import { Alert } from 'antd';
import './alert.scss';

const AlertComponent = ({
    type = '',
    className = '',
    message = '',
}) => {
    return (
        <Alert className={className} message={message} type={type} />
    );
};

export default AlertComponent;