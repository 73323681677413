import { Layout } from 'antd';

const { Sider } = Layout;

const SiderComponent = ({ width = '15vw', breakpoint = 'lg', ...props }) => {
    return (
        <Sider
            width={width}
            breakpoint={breakpoint}
            collapsed={props?.collapsed}
            onCollapse={props?.onCollapse}
            collapsible
        >
            {props.children}
        </Sider>
    );
};

export default SiderComponent;
