import React from 'react';
import { Modal } from 'antd';
import './modalpopup.scss';
const ModalPopupComponent = ({
    title = '',
    visible,
    hideModal = () => {},
    footer,
    className = 'darkTheme',
    onOk = () => {},
    width = 800, // default value if the width is not specified-
    ...props
}) => {
    return (
        <div>
            <Modal
                title={title}
                open={visible}
                width={width}
                closable={true}
                onCancel={hideModal}
                footer={footer}
                maskClosable={false}
                centered
                className={'darkTheme ' + className}
                onOk={onOk}
            >
                {props?.children}
            </Modal>
        </div>
    );
};

export default ModalPopupComponent;
