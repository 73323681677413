import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import './menu.scss';

const MenuComponent = ({
    theme = 'light',
    style = {},
    mode = 'inline',
    defaultSelectedKeys = [],
    openKeys = [],
    onOpenChange = () => {},
    items = [],
}) => {
    const navigate = useNavigate();

    return (
        <Menu
            theme={theme}
            style={style}
            onClick={(e) => {
                if (e.key === 'documentation') {
                    navigate(e?.key);
                    window.open('https://customerdocs.liveline.cloud/index.html', '_blank');
                } else if (e.key === 'dashboard') {
                    navigate('/');
                } else if (e.key === 'contact_us') {
                    navigate(e?.key);
                    window.open('https://liveline.atlassian.net/servicedesk/customer/portals', '_blank');
                } else {
                    navigate(e?.key);
                }
            }}
            mode={mode}
            defaultSelectedKeys={defaultSelectedKeys}
            items={items}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
        />
    );
};

export default MenuComponent;
