import React from 'react';

import { Typography } from 'antd';

const { Text } = Typography;

const TypographyTextComponent = ({ className = '', style = {}, ellipsis = true, ...props }) => {
    return (
        <Text ellipsis={ellipsis} style={style} className={className}>
            {props.children}
        </Text>
    );
};

export default TypographyTextComponent;
