import React from 'react';
import { Drawer } from 'antd';
import './drawer.scss';

const DrawerComponent = ({
    title = '',
    className = '',
    onClose = () => {},
    placement = 'right',
    open,
    width = 800,
    ...props
}) => {
    return (
        <>
            <Drawer
                width={width}
                title={title}
                placement={placement}
                onClose={onClose}
                open={open}
                className={className}
            >
                {props?.children}
            </Drawer>
        </>
    );
};

export default DrawerComponent;
