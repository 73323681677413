import React from 'react';

/**
 * Embeds a report as an image into the page
 * `imagePath` is a required `prop`
 * See `defaultProps` for other parameters. */
class ImageReport extends React.Component {
    render() {
        const { imagePath, altText, width, height, className } = this.props;
        var optProps = {};
        if (width) {
            optProps.width = width;
        }
        if (height) {
            optProps.height = height;
        }
        if (className) {
            optProps.className = className;
        }

        return (
            <>
                <img src={imagePath} alt={altText} style={{ verticalAlign: 'top' }} {...optProps} />
            </>
        );
    }
}

ImageReport.defaultProps = {
    /** Alternative text for image.
     * Highly recommended this gets passed in.
     */
    altText: 'Report image',
    /** Width of image component;
     * if `undefined` then original image width or layout constraints determine sizing. */
    width: undefined,
    /** Height of image component;
     * if `undefined` then original image width or layout constraints determine sizing. */
    height: undefined,
    /** Optional CSS class name to apply */
    className: undefined,
};
export default ImageReport;
