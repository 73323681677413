import React, { lazy } from 'react';

const ServerSummary = lazy(() => import('./index'));

const ServerSummaryRoute = () => {
    return [
        {
            key: 'server-summary',
            path: '/server-summary',
            component: <ServerSummary />,
        },
    ];
};

export default ServerSummaryRoute;
