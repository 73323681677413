import React from 'react';
import { Switch } from 'antd';
import './switch.scss';

const SwitchComponent = ({
    className = '',
    onClickEvent = () => {},
    onChangeEvent = () => {},
    style = {},
    checkedChildren = '',
    unCheckedChildren = '',
    defaultChecked = false,
    disabled = false,
    checked = false,
    size = 'small',
    ...props
}) => {
    return (
        <Switch
            className={className}
            onChange={onChangeEvent}
            onClick={onClickEvent}
            style={style}
            disabled={disabled}
            checkedChildren={checkedChildren}
            unCheckedChildren={unCheckedChildren}
            defaultChecked={defaultChecked}
            checked={checked}
            size={size}
        >
            {props.children}
        </Switch>
    );
};

export default SwitchComponent;
