import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

function createInitialState() {
    return {
        facility: {},
        cell: {},
        product: {},
        error: null,
        loading: false,
    };
}

function createExtraReducers() {
    return (builder) => {
        setUserSelectionInfo();
        function setUserSelectionInfo() {
            const { pending, fulfilled, rejected } = extraActions.setUserSelectionInfo;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                    state.loading = true;
                })
                .addCase(fulfilled, (state, action) => {
                    let payload = action.payload;
                    state.facility = payload?.facility;
                    state.cell = payload?.cell;
                    state.product = payload?.product;
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error.message;
                    state.loading = false;
                });
        }
    };
}

function createExtraActions() {
    return {
        setUserSelectionInfo: setUserSelectionInfo(),
    };

    function setUserSelectionInfo() {
        return createAsyncThunk(`${name}/setUserSelectionInfo`, async (userSelectionInfo) => {
            return userSelectionInfo;
        });
    }
}

const name = 'userSelectionInfo';
const initialState = createInitialState();
const extraReducers = createExtraReducers();
const extraActions = createExtraActions();

const slice = createSlice({ name, initialState, extraReducers });

export const userSelectionActions = { ...slice.actions, ...extraActions };
export const userSelectionReducer = slice.reducer;
