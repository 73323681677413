import React from 'react';

import { Confirm } from '../index';
import { info } from '../../../assets/dark-theme';
import './informationPopup.scss';

const InformationPopUp = ({ className = '', title = '', content = '' }) => {
    const onShowPopup = () => {
        Confirm({
            title: title,
            icon: '',
            content: content,
            onOk() {},
            onCancel() {},
        });
    };
    return (
        <div className='toolbar-help'>
            <img
                src={info}
                alt='help'
                onClick={onShowPopup}
                className='helpIcon'
            />
        </div>
    );
};

export default InformationPopUp;
