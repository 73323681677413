const fileSortData = (a, b) => {
    try {
        if ((a || a >= 0) && (b || b >= 0)) {
            if (typeof a == 'string' && typeof b == 'string') {
                if (a.toUpperCase() > b.toUpperCase()) {
                    return -1;
                }
                if (a.toUpperCase() < b.toUpperCase()) {
                    return 1;
                }
            } else {
                return a - b;
            }
        }
        return 0;
    } catch (ex) {
        console.error(ex);
        return 0;
    }
};

const orderOption = (order) => {
    if (order === 'ascend' || order === 'asc') {
        return 'asc';
    }
    return 'desc';
};

const selectSortFunction = (optionA, optionB) => {
    const first = Number(optionA);
    const second = Number(optionB);
    if (!isNaN(first) && !isNaN(second)) {
        return first - second;
    }
    return optionA.toLowerCase().localeCompare(optionB.toLowerCase());
};


const mMulti = 60, hMulti = 24;

const inMinutes = value => {
    const unit = value.slice(value.length - 1);
    const numValue = parseInt(value.slice(0, value.length - 1));

    switch (unit) {
        case 'h':
            return numValue * mMulti;
        case 'd':
            return numValue * mMulti * hMulti;
        default:
            return numValue;
    }
};

const inMilliseconds = value => inMinutes(value) * 60000;

const addDash = valueOnlyFlag => valueOnlyFlag ? '' : '-';

const toTimeString = (value, valueOnlyFlag) => { // time in minutes, turned into time string for grafana dashboard url ex. 68 => "-1h-8m" or "1h8m"
    let timeString = '';
    let remainingValue = value;

    const minutes = remainingValue % mMulti;

    if (minutes) timeString = `${addDash(valueOnlyFlag)}${minutes}m` + timeString;
    remainingValue -= minutes;

    if (remainingValue) {
        remainingValue /= mMulti;
        const hours = remainingValue % hMulti;

        if (hours) timeString = `${addDash(valueOnlyFlag)}${hours}h` + timeString;
        remainingValue -= hours;

        if (remainingValue) {
            remainingValue /= hMulti;
            const days = remainingValue;

            if (days) timeString = `${addDash(valueOnlyFlag)}${days}d` + timeString;
        }
    }

    return timeString;
};

const getIndexByKeyValue = (key, val, arr = []) => {
    if (key && val != null) return arr.map((o) => o[key]).indexOf(val);

    return -1;
}

function isEmpty(obj) {
    if (obj) {
        for (const prop in obj) {
            if (Object.hasOwn(obj, prop)) {
                return false;
            }
        }
    }

    return true;
}

function isValiudUrl(url) {
    try {
        new URL(url);
        return true;
    } catch (err) {
        return false;
    }
}

function isString(val) {
    return typeof val === 'string' || val instanceof String;
}
export { fileSortData, orderOption, selectSortFunction, inMinutes, toTimeString, inMilliseconds, getIndexByKeyValue, isEmpty, isValiudUrl, isString };
